// import('bootstrap');

// import Swiper JS
import Swiper from 'swiper/swiper-bundle';
// import Swiper styles
import 'swiper/swiper-bundle.css';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

//Init Swiper carousels
var storiesSwiper = new Swiper(".more-stories .swiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        // when window width is >= 640px
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        }
    },
    pagination: {
        el: ".more-stories .swiper-pagination",
        clickable: true,
    },
});

var moreServiceSwiper = new Swiper(".more-services-carousel .swiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        // when window width is >= 640px
        992: {
            slidesPerView: 4,
            spaceBetween: 30
        }
    },
    pagination: {
        el: ".more-services-carousel .swiper-pagination",
        clickable: true,
    },
});

AOS.init();
